import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper';

// Components
import TestimonialCard from '../../components/card/Testimonial';

const TestimonialsSection = ({ site, sectionStyle }) => {

  const query = useStaticQuery(graphql`
    query {
      testimonials: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/testimonials/" },
          frontmatter: { home: { eq: true }, active: { eq: true } }
        },
        sort: {fields: frontmatter___date, order: DESC}
      )
      {
        nodes {
          frontmatter {
            name
            rating
            website
            link
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
          body
        }
      }
      backgroundImage: file(relativePath: { eq: "backgrounds/temoignages-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const backgroundImage = query.backgroundImage.childImageSharp.fluid

  return (
    <>
      {sectionStyle === "1" &&
        <section id="testimnonials" className="bg-silver-light">
          <div className="container">
            <div className="section-title">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Ce que <span className="text-theme-colored1">nos clients</span> disent de <span className="text-theme-colored2">{site.siteMetadata.title}</span></h2>
                        <div className="title-seperator-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <Swiper
                className="swiper"
                modules={[A11y, Autoplay]}
                autoplay={{ delay: 5000, disableOnInteraction: true }}
                scrollbar={{ draggable: true }}
                data-aos="fade-up" data-aos-delay="-50"
                breakpoints={{
                  "380": {
                    "slidesPerView": 1,
                    "spaceBetween": 5,
                  },
                  "580": {
                    "slidesPerView": 2,
                    "spaceBetween": 10,
                  },
                  "768": {
                    "slidesPerView": 2,
                    "spaceBetween": 10,
                  },
                  "1024": {
                    "slidesPerView": 2,
                    "spaceBetween": 30,
                  }
                }}
              >
                {query.testimonials.nodes.map((testimonial, index) => (
                  <SwiperSlide key={testimonial.id} className="swiper-slide">
                    <TestimonialCard key={testimonial.id} data={testimonial} cardStyle="1" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      }
      {sectionStyle === "2" &&
        <BackgroundImage
          id="testimnonials"
          Tag="section"
          fluid={backgroundImage}
        >
          <div className="container pt-90 pb-90">
            <div className="section-title section-typo-light">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Ce que <span className="text-theme-colored2">nos clients</span> disent de <span className="text-theme-colored2">{site.siteMetadata.title}</span></h2>
                        <div className="title-seperator-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <Swiper
                className="swiper"
                modules={[A11y, Autoplay]}
                autoplay={{ delay: 5000, disableOnInteraction: true }}
                scrollbar={{ draggable: true }}
                data-aos="fade-up" data-aos-delay="-50"
                breakpoints={{
                  "380": {
                    "slidesPerView": 1,
                    "spaceBetween": 5,
                  },
                  "580": {
                    "slidesPerView": 2,
                    "spaceBetween": 10,
                  },
                  "768": {
                    "slidesPerView": 2,
                    "spaceBetween": 10,
                  },
                  "1024": {
                    "slidesPerView": 2,
                    "spaceBetween": 30,
                  }
                }}
              >
                {query.testimonials.nodes.map((testimonial, index) => (
                  <SwiperSlide key={testimonial.id} className="swiper-slide">
                    <TestimonialCard key={testimonial.id} data={testimonial} cardStyle="1" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </BackgroundImage>
      }
    </>
  )
}

export default TestimonialsSection