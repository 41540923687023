import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import slugify from '@sindresorhus/slugify';

const ServiceCard = ({ data, city, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="-50">
          <Link to={`/service/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="tm-sc tm-sc-services services-style-current-theme mb-30">
              <div className="tm-service services type-services status-publish has-post-thumbnail hentry services_category-industry">
                <div className="thumb">
                  <div className="thumb-inner">
                    <GatsbyImage
                      className="img-fullwidth"
                      image={getImage(data.frontmatter.image.card)}
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                </div>
                <div className="details">
                  <div className='symbol-icon'>
                    <GatsbyImage
                      image={getImage(data.frontmatter.image.icon)}
                      alt={data.frontmatter.image.alt}
                    />
                  </div>
                  {data.frontmatter.title &&
                  <h5 className="title">{data.frontmatter.title}</h5>
                  } 
                  {data.frontmatter.type &&
                    <ul className="cat-list">
                      <li>
                        <Link to={`/service/${data.slug}`} aria-label={data.frontmatter.image.alt}>
                          {data.frontmatter.type}
                        </Link>                        
                      </li>
                    </ul>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
      {cardStyle === "2" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="-50">
          <Link to={`/service/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="tm-sc tm-sc-icon-box icon-box icon-top text-center iconbox-centered-in-responsive iconbox-theme-colored1 icon-position-icon-top">
              <div className="icon-box-wrapper">
                <div className="icon-wrapper">
                  <GatsbyImage
                    className="icon icon-type-font-icon icon-dark icon-circled font-size-60"
                    imgClassName="p-3"
                    image={getImage(data.frontmatter.image.icon)}
                    alt={data.frontmatter.image.alt}
                  />
                </div>
                <div className="icon-text">
                  {data.frontmatter.title &&
                    <h4 className="icon-box-title">{data.frontmatter.title}</h4>
                  } 
                  {data.frontmatter.description &&
                    <div className="content">
                      <p>{data.frontmatter.description}</p>
                    </div>
                  }
                  <div className="btn-view-details"> 
                    <Link to={`/service/${data.slug}`} className="btn btn-plain-text-with-arrow btn-sm" aria-label={data.frontmatter.image.alt}>
                      En savoir plus
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
      {cardStyle === "3" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="-50">
          <Link to={`/service/${slugify(city.name) + '-' + data.slug}`} aria-label={data.frontmatter.image.alt.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}>
            <div className="tm-sc tm-sc-icon-box icon-box icon-top text-center iconbox-centered-in-responsive iconbox-theme-colored1 icon-position-icon-top">
              <div className="icon-box-wrapper">
                <div className="icon-wrapper">
                  <GatsbyImage
                    className="icon icon-type-font-icon icon-dark icon-circled font-size-60"
                    imgClassName="p-3"
                    image={getImage(data.frontmatter.image.icon)}
                    alt={data.frontmatter.image.alt.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}
                  />
                </div>
                <div className="icon-text">
                  {data.frontmatter.title &&
                    <h4 className="icon-box-title">{data.frontmatter.title}</h4>
                  } 
                  {data.frontmatter.description &&
                    <div className="content">
                      <p>{data.frontmatter.description.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}</p>
                    </div>
                  }
                  <div className="btn-view-details"> 
                    <Link to={`/service/${slugify(city.name) + '-' + data.slug}`} className="btn btn-plain-text-with-arrow btn-sm" aria-label={data.frontmatter.image.alt.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}>
                      En savoir plus
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
    </>
  )
}

export default ServiceCard