import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';
import Tilty from 'react-tilty';

// Components
import Layout from '../components/layout/Layout';
import ServiceCard from '../components/card/Service';
import TestimonialsSection from '../components/section/Testimonials';
import ContactSection from '../components/section/Contact';

const InterventionZonePage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/equipe-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cities: allCitiesCsv (
        filter: { active: { eq: "1" } }
      ) {
        nodes {
          id
          name
          name_capital
          name_lowercase
          name_slug
          department
          postal_code
          population
          longitude
          latitude
          active
        }
        totalCount
      }
      services: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/local/services/" }, 
          frontmatter: { active: { eq: true } }
        },
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        nodes {
          frontmatter {
            title
            description
            image {
              icon {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              cover {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
        }
        totalCount
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Notre zone d'intervention | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      pageDescription={`L'équipe dynamique et expérimentée de ${query.site.siteMetadata.title} à votre service depuis 30 ans intervient dans un rayon de 40 km autour de Roanne.`}
      pageKeywords="pcc bâtiment, zone d'intervention, témoignages, avis, loire, rhône, travaux, plâtrerie, peinture"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Notre zone d'intervention</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Zone d'intervention</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section id="intervention-zone" className="bg-white-f5">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <h5 className="tm-sc tm-sc-line-with-text mt-0 mb-0 text-gray line-after-title "> <span className="horizontal-text">Depuis 30 ans</span> <span className="horizontal-line bg-theme-colored1"></span></h5>
                <div className="tm-sc section-title section-title-style1 text-left">
                  <div className="title-wrapper">
                    <h2 className="title"> <span>À votre </span> <span className="text-theme-colored1">service</span></h2>
                    <div className="title-seperator-line"></div>
                  </div>
                </div>
                <p>{query.site.siteMetadata.title} met son savoir-faire au service des particuliers et des professionnels. Notre équipe dynamique et expérimentée mettra tout en œuvre afin de réaliser vos projets dans un rayon de 40 km autour de Roanne.</p>
                <div className="tm-sc tm-sc-icon-box icon-box icon-left tm-iconbox-icontype-font-icon text-left iconbox-theme-colored1 icon-position-icon-left mt-40 mb-40">
                  <div className="icon-box-wrapper">
                    <span className="icon icon-type-font-icon font-size-60">
                      <StaticImage src="../images/about/experimente-pcc-batiment.png" alt={`${query.site.siteMetadata.title} a une équipe dynamique qui réalise vos projets en respectant les délais, vos exigences et votre budget`} />
                    </span>
                    <div className="icon-text">
                      <h5 className="icon-box-title mt-0"> Expérimenté</h5>
                      <div className="content">
                        <p>Une équipe dynamique qui réalise vos projets en respectant les délais, vos exigences et votre budget.</p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="tm-sc tm-sc-icon-box icon-box icon-left tm-iconbox-icontype-font-icon text-left iconbox-theme-colored1 icon-position-icon-left mb-40">
                  <div className="icon-box-wrapper">
                    <span className="icon icon-type-font-icon font-size-60">
                      <StaticImage src="../images/about/proximite-pcc-batiment.png" alt={`${query.site.siteMetadata.title} se situe à Roanne et intervient dans ses alentours`} />
                    </span>
                    <div className="icon-text">
                      <h5 className="icon-box-title mt-0"> Proximité</h5>
                      <div className="content">
                        <p>{query.site.siteMetadata.title} se situe à Roanne et intervient dans ses alentours.</p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="tm-sc tm-sc-icon-box icon-box icon-left tm-iconbox-icontype-font-icon text-left iconbox-theme-colored1 icon-position-icon-left mb-40">
                  <div className="icon-box-wrapper">
                    <span className="icon icon-type-font-icon font-size-60">
                      <StaticImage src="../images/about/qualite-pcc-batiment.png" alt={`${query.site.siteMetadata.title} est certifiée Qualibat RGE a de très nombreuses réalisations à son actif, tant en construction qu'en rénovation`} />
                    </span>
                    <div className="icon-text">
                      <h5 className="icon-box-title mt-0"> Qualité</h5>
                      <div className="content">
                        <p>L'entreprise certifiée Qualibat RGE a de très nombreuses réalisations à son actif, tant en construction qu'en rénovation.</p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
                  <StaticImage src="../images/intervention-zone/zone-d-intervention-pcc-batiment.png" alt={`Zone d'intervention de ${query.site.siteMetadata.title} dans un rayon de 40 km autour de Roanne : Le Coteau, Riorges, Perreux, Mably, Commelle-Vernay, Villerest, Saint-Léger-sur-Roanne, Parigny, Ouches, Pouilly-les-Nonains, Lentigny`} />
                </Tilty>
                <div className="row mt-30">
                  <div className="col-sm-12">
                    <div className="tm-sc tm-sc-button text-center mr-20">
                      <Link to={`/contact/`} className="btn btn-theme-colored2">Contactez-nous</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {query.cities && query.cities.nodes.length ?
        <>
          {query.cities.nodes.map((city, index) => (
            <section id="services" className={index % 2 === 0 ? "bg-silver-light" : ""}>
              <div className="container">
                <div className="section-title">
                  <div className="row justify-content-md-center">
                    <div className="col-md-8">
                      <div className="text-center mb-60">
                        <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                          <div className="title-wrapper">
                            <h2 className="title">Nos <span className="text-theme-colored1">services</span> à <span className="text-theme-colored2">{city.name}</span></h2>
                            <div className="title-seperator-line"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-content">
                  <div className="row">
                    {query.services.nodes.map(service => (
                      <ServiceCard key={service.id} data={service} city={city} cardStyle="3" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          ))}
        </>
        : null}
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <ContactSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default InterventionZonePage
